import { onMounted, ref, watchEffect } from 'vue'

import type { Context } from '@backmarket/http-api/src/api-specs-content/models/base-page-content'
import { useUserStore } from '@backmarket/nuxt-layer-oauth/useUserStore'
import { useTypeUnsafeHttpFetch as useHttpFetch } from '@backmarket/nuxt-module-http-v2/useHttpFetch'
import { useLogger } from '@backmarket/nuxt-module-logger/useLogger'
import { isEmpty } from '@backmarket/utils/object/isEmpty'

import { usePageParamsFromRoute } from './usePageParamsFromRoute'

const PAGE_CONTEXT_ENDPOINT = '/content/admin/page/:pageType/:pageName' as const
const PAGE_CATEGORY_CONTEXT_ENDPOINT =
  '/content/admin/page/:pageType/:pageName/:pageCategory' as const

export function useFetchPageContext() {
  const userStore = useUserStore()
  const pathParams = usePageParamsFromRoute()
  const $httpFetch = useHttpFetch()
  const logger = useLogger()

  const context = ref<Context | null>(null)

  onMounted(() => {
    watchEffect(async () => {
      if (!userStore.user.isStaff) return

      const endpoint = isEmpty(pathParams.pageCategory)
        ? PAGE_CONTEXT_ENDPOINT
        : PAGE_CATEGORY_CONTEXT_ENDPOINT

      try {
        context.value = await $httpFetch<Context>(endpoint, { pathParams })
      } catch (error) {
        logger.error('[CMS CONTEXT FETCH FAILED]', {
          error: error as Error,
          owners: ['bot-squad-content-front'],
        })
      }
    })
  })

  return context
}
